/* 

TODO LIST

2) display images as app icon "shape square circle" in iPad and iPhone view

3) investigate some sort of animations

*/


/* MARK - START MISC */



html {
  height: 100%;
  overflow-x: hidden;


}

.headerTextCompany {
  font-size: 30px;
}

.footer {
  position: static;
  bottom: 0;
  width: 100%;
  height: 52.5px;
}

#footerBackground {
  /* The image used */
  background-image: url("../src/script/test-img/new.gif");
  /* background-size: contain; */

  /* Full height */
  height: 100vh;
  background-color: black;
  /* image-rendering: pixelated; */

  /* Center and scale the image nicely */
  background-repeat: no-repeat;
  background-position-x: 15.45%;
  background-position-y: 60%;
  background-size: cover;
  overflow-x: scroll;

}

body {
  margin: 0;
}

.footerBackground {
  background-image: "footer.gif";
}

/* .menuItems a p {
  border-bottom: solid red; 
} */

/* MARK - END MISC */

/* MARK - START HEADER */

.flex-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: black;
border-bottom: 1px solid #282828;

  z-index: 2;
}



.viewMore span {

  -webkit-text-size-adjust: 100%;
  --tds-animation-bezier-base: cubic-bezier(0.5, 0, 0, 0.75);
  --tds-ui-opacity-100: 1;
  --tds-ui-opacity-70: 0.7;
  --tds-ui-opacity-50: 0.5;
  --tds-ui-opacity-30: 0.3;
  --tds-ui-opacity-0: 0;
  --tds-ui-box-shadow-off: 0 0 0 0 transparent;
  --tds-component-pill-padding-inline: 20px;
  --tds-size-half: 4px;
  --tds-size-base: 8px;
  --tds-size-9x: 72px;
  --tds-size-8x: 64px;
  --tds-size-7x: 56px;
  --tds-size-6x: 48px;
  --tds-size-5x: 40px;
  --tds-size-4x: 32px;
  --tds-size-3x: 24px;
  --tds-size-2x: 16px;
  --tds-size-13x: 104px;
  --tds-size-12x: 96px;
  --tds-size-11x: 88px;
  --tds-size-10x: 80px;
  --tds-font-weight-xlight: 200;
  --tds-font-weight-medium: 500;
  --tds-font-weight-light: 300;
  --tds-font-weight-book: 400;
  --tds-font-weight-bold: 700;
  --tds-font-size-75: 34px;
  --tds-font-size-70: 28px;
  --tds-font-size-55: 23px;
  --tds-font-size-50: 20px;
  --tds-font-size-40: 17px;
  --tds-font-size-30: 14px;
  --tds-font-size-20: 12px;
  --tds-font-size-10: 10px;
  --tds-font-line-height-unitless: 1.414;
  --tds-font-line-height-75: 45px;
  --tds-font-line-height-70: 36px;
  --tds-font-line-height-60: 28px;
  --tds-font-line-height-40: 23px;
  --tds-font-line-height-30: 21px;
  --tds-font-line-height-20: 20px;
  --tds-font-line-height-10: 18px;
  --tds-font-family-thai: "Noto Sans Thai";
  --tds-font-family-monospace: "Fira Code", SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  --tds-font-family-latin-alt: "Universal Sans";
  --tds-font-family-latin: "Gotham SSm";
  --tds-font-family-korean: "FB New Gothic";
  --tds-font-family-japanese: "AXIS Font Japanese W55", "Hiragino Sans";
  --tds-font-family-hebrew: "Noto Sans Hebrew";
  --tds-font-family-chinese-traditional: "PingFang TC", "Microsoft YaHei";
  --tds-font-family-chinese-simplified: "PingFang SC", "Microsoft YaHei";
  --tds-font-family-chinese-hk: "PingFang HK", "Microsoft YaHei";
  --tds-font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --tds-font-family-arabic: "Noto Sans Arabic";
  --tds-color-yellow: #fbb01b;
  --tds-color-white: #fff;
  --tds-color-red-20: #ed4e3b;
  --tds-color-red-10: #b74134;
  --tds-color-grey-70: #f4f4f4;
  --tds-color-grey-65: #eee;
  --tds-color-grey-60: #e2e3e3;
  --tds-color-grey-50: #d0d1d2;
  --tds-color-grey-45: #bbb;
  --tds-color-grey-40: #a2a3a5;
  --tds-color-grey-35: #8e8e8e;
  --tds-color-grey-30: #5c5e62;
  --tds-color-grey-25: #444;
  --tds-color-grey-20: #393c41;
  --tds-color-grey-15: #222;
  --tds-color-grey-10: #171a20;
  --tds-color-green: #12bb00;
  --tds-color-blue-40: #3368ff;
  --tds-color-blue-30: #3e6ae1;
  --tds-color-blue-20: #3457b1;
  --tds-color-black: #000;
  --tds-border-width-small: 1px;
  --tds-border-width-medium: 2px;
  --tds-border-width-large: 3px;
  --tds-border-width-hairline: 0.5px;
  --tds-border-radius-outline-only: 0.001px;
  --tds-border-radius-form-item: 20px;
  --tds-border-radius-circle: 100%;
  --tds-ui-box-shadow-small: 0 var(--tds-size-half) var(--tds-size-base) 0 rgba(0, 0, 0, .08);
  --tds-ui-box-shadow-medium: 0 var(--tds-size-base) var(--tds-size-2x) 0 rgba(0, 0, 0, .12);
  --tds-ui-box-shadow-large-reverse: 0 -8px var(--tds-size-2x) 0 rgba(0, 0, 0, .16);
  --tds-ui-box-shadow-large: 0 var(--tds-size-base) var(--tds-size-2x) 0 rgba(0, 0, 0, .16);
  --tds-component-pill-padding-block: var(--tds-size-2x);
  --tds-component-pill-height: var(--tds-size-5x);
  --tds-component-card-padding: var(--tds-size-2x);
  --tds-size-1x: var(--tds-size-base);
  --tds-font-size-80: var(--tds-size-5x);
  --tds-font-size-60: var(--tds-size-3x);
  --tds-font-line-height-80: var(--tds-size-6x);
  --tds-font-line-height-50: var(--tds-size-3x);
  --tds-border-radius-pill: var(--tds-size-4x);
  --tds-border-radius-card: var(--tds-size-base);
  --tds-border-radius-base: var(--tds-size-half);
  --tds-theme-background-container-alt-highlight: rgba(0, 0, 0, .075);
  --tds-theme-background-container-alt: rgba(0, 0, 0, .05);
  --tds-theme-secondary-warning: var(--tds-color-yellow);
  --tds-theme-secondary-positive: var(--tds-color-green);
  --tds-theme-secondary-negative: var(--tds-color-red-10);
  --tds-theme-foreground-primary: var(--tds-color-blue-30);
  --tds-theme-foreground-low-contrast: var(--tds-color-grey-30);
  --tds-theme-foreground-inverse: var(--tds-color-black);
  --tds-theme-foreground-high-contrast: var(--tds-color-grey-10);
  --tds-theme-foreground: var(--tds-color-grey-20);
  --tds-theme-border-low-contrast: var(--tds-color-grey-60);
  --tds-theme-border: var(--tds-color-grey-50);
  --tds-theme-background-dialog: var(--tds-color-white);
  --tds-theme-background: var(--tds-color-white);
  --tds-theme-background-container-highlight: var(--tds-color-grey-65);
  --tds-theme-background-container: var(--tds-color-grey-70);
  --tds-bezier: cubic-bezier(0.5, 0, 0, 0.75);
  --tds-size: 8px;
  --tds-size--10x: 80px;
  --tds-size--11x: 88px;
  --tds-size--12x: 96px;
  --tds-size--13x: 104px;
  --tds-size--1x: 8px;
  --tds-size--2x: 16px;
  --tds-size--3x: 24px;
  --tds-size--4x: 32px;
  --tds-size--5x: 40px;
  --tds-size--6x: 48px;
  --tds-size--7x: 56px;
  --tds-size--8x: 64px;
  --tds-size--9x: 72px;
  --tds-size--half: 4px;
  --tds-color--black: #000;
  --tds-color--blue10: #2e4994;
  --tds-color--blue20: #3457b1;
  --tds-color--blue30: #3e6ae1;
  --tds-color--green: #12bb00;
  --tds-color--grey10: #171a20;
  --tds-color--grey15: #222;
  --tds-color--grey20: #393c41;
  --tds-color--grey25: #444;
  --tds-color--grey30: #5c5e62;
  --tds-color--grey35: #8e8e8e;
  --tds-color--grey40: #a2a3a5;
  --tds-color--grey45: #bbb;
  --tds-color--grey50: #d0d1d2;
  --tds-color--grey60: #e2e3e3;
  --tds-color--grey65: #eee;
  --tds-color--grey70: #f4f4f4;
  --tds-color--red10: #b74134;
  --tds-color--red20: #ed4e3b;
  --tds-color--white: #fff;
  --tds-color--yellow: #fbb01b;
  --tds-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --tds-font-family--arabic: "Noto Sans Arabic";
  --tds-font-family--chinese-hk: "PingFang HK", "Microsoft YaHei";
  --tds-font-family--chinese-simplified: "PingFang SC", "Microsoft YaHei";
  --tds-font-family--chinese-traditional: "PingFang TC", "Microsoft YaHei";
  --tds-font-family--hebrew: "Noto Sans Hebrew";
  --tds-font-family--japanese: "AXIS Font Japanese W55", "Hiragino Sans";
  --tds-font-family--korean: "FB New Gothic";
  --tds-font-family--latin: "Gotham SSm";
  --tds-font-family--monospace: "Fira Code", SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  --tds-font-family--thai: "Noto Sans Thai";
  --tds-font-weight--bold: 700;
  --tds-font-weight--book: 400;
  --tds-font-weight--light: 300;
  --tds-font-weight--medium: 500;
  --tds-font-weight--xlight: 200;
  --tds-blur--button: 16px;
  --tds-blur--large: 8px;
  --tds-blur--small: 4px;
  --tds-border-radius: 4px;
  --tds-border-radius--card: 4px;
  --tds-border-radius--card--dense: 4px;
  --tds-border-radius--circle: 100%;
  --tds-border-radius--outline-only: 0.001px;
  --tds-border-style: solid;
  --tds-border-width--large: 3px;
  --tds-border-width--medium: 2px;
  --tds-border-width--small: 1px;
  --tds-box-shadow--large: 0 8px 16px 0 rgba(0, 0, 0, .16);
  --tds-box-shadow--large-reverse: 0 -8px 16px 0 rgba(0, 0, 0, .16);
  --tds-box-shadow--medium: 0 8px 16px 0 rgba(0, 0, 0, .12);
  --tds-box-shadow--off: 0 0 0 0 transparent;
  --tds-box-shadow--small: 0 4px 8px 0 rgba(0, 0, 0, .08);
  --tds-font-size--10: 10px;
  --tds-font-size--20: 12px;
  --tds-font-size--30: 14px;
  --tds-font-size--40: 17px;
  --tds-font-size--50: 20px;
  --tds-font-size--55: 23px;
  --tds-font-size--60: 24px;
  --tds-font-size--70: 28px;
  --tds-font-size--75: 34px;
  --tds-font-size--80: 40px;
  --tds-line-height--10: 18px;
  --tds-line-height--20: 20px;
  --tds-line-height--30: 20px;
  --tds-line-height--40: 24px;
  --tds-line-height--50: 24px;
  --tds-line-height--60: 28px;
  --tds-line-height--70: 36px;
  --tds-line-height--75: 44px;
  --tds-line-height--80: 48px;
  --tds-line-height--unitless: 1.414;
  --tds-opacity--100: 1;
  --tds-opacity--30: 0.3;
  --tds-opacity--50: 0.5;
  --tds-opacity--70: 0.7;
  --tds-opacity--transparent: 0;
  --tds-padding--card: 24px;
  --tds-padding--card--dense: 16px;
  --tds-border-width--hairline: 0.5px;
  --tds-base-font_family: var(--tds-font-family--combined);
  --tds-base-font_size: var(--tds-font-size--30);
  --tds-base-line_height: var(--tds-line-height--30);
  --tds-color--error: var(--tds-color-red-10);
  --tds-color--hairline: var(--tds-color-grey-50);
  --tds-color--inverse: var(--tds-color-white);
  --tds-color--rgb-shade: 0, 0, 0;
  --tds-color--rgb-shade--inverse: 255, 255, 255;
  --tds-color--scrim--semitransparent: hsla(0, 0%, 100%, .7);
  --tds-color--secondary: var(--tds-color-grey-10);
  --tds-color--secondary-accent: var(--tds-color-grey-50);
  --tds-color--secondary-highlight: var(--tds-color-black);
  --tds-color--tertiary: var(--tds-color-grey-70);
  --tds-color--tertiary-highlight: var(--tds-color-grey-65);
  --tds-caption--color: var(--tds-color-grey-30);
  --tds-dialog--background-color: var(--tds-color-white);
  --tds-form-label--color: var(--tds-color-grey-30);
  --tds-heading--color: var(--tds-color-grey-10);
  --tds-pill--blur-color: rgba(0, 0, 0, .048);
  --tds-pill--blur-color-highlight: rgba(0, 0, 0, .072);
  --tds-text--color: var(--tds-color-grey-20);
  --tds-text--color--light: var(--tds-color-grey-30);
  --tds-text--color--placeholder: var(--tds-color-grey-40);
  --tds-text--color--primary: var(--tds-color-blue-30);
  --tds-toggle--background-color: hsla(0, 0%, 59%, .1);
  --tds-toggle--fill-color: var(--tds-color-white);
  --tds-text--contrast-high: var(--tds-color-grey-10);
  --tds-text--contrast-low: var(--tds-color-grey-30);
  --tds-text--contrast-medium: var(--tds-color-grey-20);
  --tds-code-bg-color: var(--tds-color-grey-70);
  --tds-code-border-color: var(--tds-color-grey-60);
  --tds-code-color: var(--tds-text--color);
  --tds-color--focus: var(--tds-color-grey-35);
  --tds-outline--focus: 2px solid var(--tds-color--focus);
  --tds-background-image--check: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="hsl(218, 7%, 24%)" d="M6.592 11.58a.748.748 0 0 1-.53-.22L3.22 8.517a.75.75 0 1 1 1.06-1.061l2.312 2.312L11.72 4.64a.75.75 0 1 1 1.06 1.06l-5.658 5.66a.748.748 0 0 1-.53.22z"/></svg>');
  --tds-background-image--minus: url('data:image/svg+xml;charset=utf-8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="hsl(218, 7%, 24%)" d="M13 8a.75.75 0 0 1-.75.75h-8.5a.75.75 0 0 1 0-1.5h8.5A.75.75 0 0 1 13 8z"/></svg>');
  --tds-border-radius--pill: var(--tds-size--half);
  --tds-data-table--font-size: 14px;
  --tds-height--pill: var(--tds-size--5x);
  --tds-form--gap: var(--tds-size--3x);
  --tds-form--indent: 0;
  --tds-form-input--collapsed-inline-size: var(--tds-size--5x);
  --tds-form-input--collapsed-padding-inline-start: var(--tds-size--1x);
  --tds-form-input-choice--check-size: var(--tds-size--2x);
  --tds-form-input-choice--radio-size: 10px;
  --tds-height--choice: var(--tds-size--3x);
  --tds-banner--padding: var(--tds-size--2x);
  --tds-btn--font-size: var(--tds-font-size--30);
  --tds-card--border-radius: var(--tds-border-radius--card);
  --tds-card--padding: var(--tds-padding--card);
  --tds-content_container--gutter: var(--tds-size--3x);
  --tds-content_container--inline-size: 100%;
  --tds-content_container--max_width: 100%;
  --tds-icon--bg: transparent;
  --tds-icon--size: 24px;
  --tds-gutter: var(--tds-size--3x);
  --tds-gutter--half: calc(var(--tds-size--3x)/2);
  --tds-gutter--negative: calc(var(--tds-size--3x)*-1);
  --tds-gutter--half_negative: calc(var(--tds-gutter--negative)/2);
  --tds-shell-footer-height: initial;
  --tds-shell-header-height: var(--tds-size--7x);
  --tds-modal--height: auto;
  --tds-modal--width: 100vw;
  --tds-modal-backdrop--rgb: 0, 0, 0;
  --tds-page-layout--footer-start-space: var(--tds-size--12x);
  --tds-page-layout--title-end-space: var(--tds-size--4x);
  --tds-page-layout--title-start-space: var(--tds-size--6x);
  --tds-page-layout--title-top-slot-space: var(--tds-size--1x);
  --tds-site-header--height: var(--tds-size--7x);
  --tds-switch_toggle--font-size: var(--tds-font-size--20);
  --tds-switch_toggle--size: 32px;
  --tds-table--vertical-align: middle;
  --tds-table-background-color: transparent;
  --tds-table-border_color: var(--tds-color-grey-50);
  --tds-table-border_spacing: 0;
  --tds-table-border_width: 1px;
  --tds-table-caption-background-color: transparent;
  --tds-table-caption-top: 0;
  --tds-table-header-font_size: var(--tds-font-size--30);
  --tds-table-padding--horizontal: var(--tds-size--2x);
  --tds-table-padding--vertical: var(--tds-size--2x);
  --tds-table-deux-table-border-width: 1px;
  --tds-table-deux-table-cell-align-items: normal;
  --tds-table-deux-table-cell-before-padding-block-end: 0;
  --tds-table-deux-table-cell-padding-horizontal: var(--tds-size--1x);
  --tds-table-deux-table-cell-padding-vertical: var(--tds-size--1x);
  --tds-table-deux-table-cell-position: static;
  --tds-table-deux-table-cell-vertical-align: middle;
  --tds-table-deux-table-column-inline-size: auto;
  --tds-table-deux-table-column-min-inline-size: 0;
  --tds-table-deux-table-header-font-size: var(--tds-table-header-font_size);
  --tds-table-deux-table-header-font-weight: var(--tds-font-weight--book);
  --tds-table-deux-table-header-line-height: var(--tds-base-line_height);
  --tds-heading--font-weight: 500;
  --tds-heading--letter-spacing: 0;
  --tds-heading--text-transform: none;
  --tds-spacer-base: var(--tds-size--half);
  --tds-spacer-1x: var(--tds-spacer-base);
  --tds-spacer-2x: calc(var(--tds-spacer-base)*2);
  --tds-spacer-3x: calc(var(--tds-spacer-base)*3);
  --tds-spacer-4x: calc(var(--tds-spacer-base)*4);
  --tds-spacer-5x: calc(var(--tds-spacer-base)*5);
  --tds-spacer-6x: calc(var(--tds-spacer-base)*6);
  --tds-spacer-8x: calc(var(--tds-spacer-base)*8);
  --tds-spacer-10x: calc(var(--tds-spacer-base)*10);
  --tds-spacer-12x: calc(var(--tds-spacer-base)*12);
  --tds-spacer-14x: calc(var(--tds-spacer-base)*14);
  --tds-spacer-16x: calc(var(--tds-spacer-base)*16);
  --tds-spacer-18x: calc(var(--tds-spacer-base)*18);
  --tds-spacer-20x: calc(var(--tds-spacer-base)*20);
  --tds-spacer-24x: calc(var(--tds-spacer-base)*24);
  --tds-spacer-32x: calc(var(--tds-spacer-base)*32);
  --tds-animate-transition-duration--medium: 600ms;
  --tds-animate-transition-duration--short: 500ms;
  --tds-animate-transition-function--base: linear;
  --tds-animate-transition-function--mktg: cubic-bezier(0.165, 0.84, 0.44, 1);
  --tds-animate-transition-property: opacity;
  --tds-animate-transition_duration--long: 1500ms;
  --tds--fade-in: tds--fade-in 1s ease;
  --tds--pulsing: tds--pulsing 1.5s infinite ease-in-out;
  --tds--rotating: tds--rotating 1.5s infinite linear;
  --tcl-grid-four-column-inline-size: inherit;
  --tcl-grid-three-column-inline-size: inherit;
  --tcl-grid-two-column-inline-size: inherit;
  --tcl-section-background-color: var(--tds-color--white);
  --tcl-showcase-screen-block-size: auto;
  --tcl-showcase-screen-inline-size: auto;
  --chrome-status-bar-height: var(--tds-size--6x);
  --safari-status-bar-height: calc(var(--tds-size--1x)*11);
  --tcl-icon--border-color: var(--tds-theme-foreground-high-contrast);
  --tcl-showcase-down-arrow-display: flex;
  --tcl-component-padding-desktop-block-end: 0;
  --tcl-component-padding-desktop-block-start: 0;
  --tcl-component-padding-mobile-block-end: 0;
  --tcl-component-padding-mobile-block-start: 0;
  --tcl-component-padding-tablet-block-end: 0;
  --tcl-component-padding-tablet-block-start: 0;
  --tcl-section-padding-desktop-block-end: 0;
  --tcl-section-padding-desktop-block-start: 0;
  --tcl-section-padding-mobile-block-end: 0;
  --tcl-section-padding-mobile-block-start: 0;
  --tcl-section-padding-tablet-block-end: 0;
  --tcl-section-padding-tablet-block-start: 0;
  --tcl-section-end-gradient-block-size: 20%;
  --tcl-section-end-gradient-opacity: 0.5;
  --tcl-section-end-gradient-end: transparent;
  --tcl-section-end-gradient-start: #000;
  --tcl-section-start-gradient-block-size: 20%;
  --tcl-section-start-gradient-opacity: 0.5;
  --tcl-section-start-gradient-end: transparent;
  --tcl-section-start-gradient-start: #000;
  --tcl-component-aspect-ratio: auto;
  --tcl-component-aspect-ratio-asset-top: 0;
  --tcl-component-aspect-ratio-asset-transform: none;
  --tcl-component-aspect-ratio-polyfill: 0;
  --tcl-component-mobile-aspect-ratio: auto;
  --tcl-component-mobile-aspect-ratio-polyfill: 0;
  --tcl-component-tablet-aspect-ratio: auto;
  --tcl-component-tablet-aspect-ratio-polyfill: 0;
  --tcl-header-background-color: var(--tds-scrim-background, transparent);
  --tcl-button-inline-size: auto;
  --tcl-button-margin-block-end: 0;
  --tcl-button-margin-block-start: 59.850px;
  --tcl-button-margin-inline-end: 0;
  --tcl-button-margin-inline-start: 0;
  --tcl-button-max-inline-size: none;
  --tcl-button-text-transform: inherit;
  --template-landing-page-footer-margin-block-start: -70px;
  --tcl-footer-display: none;
  --tcl-homepage-hero-content-display: none;
  --tcl-homepage-hero-content-z-index: -1;
  --tcl-site-header-wrapper-inset-block-start: 0;
  --tcl-video-asset-display: block;
  --tcl-video-asset-inline-size: 100%;
  --tcl-video-asset-inset-block-end: 0;
  --tcl-video-asset-inset-block-start: 0;
  --tcl-video-asset-inset-inline-start: 0;
  --tcl-video-asset-margin-block-start: 0;
  --tcl-video-asset-margin-inline-end: 0;
  --tcl-video-asset-margin-inline-start: 0;
  --tcl-video-asset-object-fit: cover;
  --tcl-video-asset-object-position-x: center;
  --tcl-video-asset-object-position-y: center;
  --tcl-video-asset-position: static;
  --tcl-video-asset-transform: none;
  --tcl-video-border-radius: 0;
  --tcl-video-duration-inset-block-end: var(--tds-size--2x);
  --tcl-video-duration-inset-inline-end: var(--tds-size--3x);
  --tcl-video-end-gradient-end: transparent;
  --tcl-video-end-gradient-opacity: 0.5;
  --tcl-video-end-gradient-size: 20%;
  --tcl-video-end-gradient-start: #000;
  --tcl-video-inline-size: 100%;
  --tcl-video-overflow: visible;
  --tcl-video-position: relative;
  --tcl-video-start-gradient-end: transparent;
  --tcl-video-start-gradient-opacity: 0.5;
  --tcl-video-start-gradient-size: 20%;
  --tcl-video-start-gradient-start: #000;
  --tcl-image-aspect-ratio-1-1-block-size: 100vw;
  --tcl-image-aspect-ratio-16-9-block-size: 177.77778vw;
  --tcl-image-aspect-ratio-3-4-block-size: 75vw;
  --tcl-image-aspect-ratio-5-3-block-size: 166.66667vw;
  --tcl-image-asset-border-radius: 0;
  --tcl-image-asset-display: block;
  --tcl-image-asset-float: none;
  --tcl-image-asset-inline-size: 100%;
  --tcl-image-asset-inset-block-end: 0;
  --tcl-image-asset-inset-block-start: 0;
  --tcl-image-asset-inset-inline-start: 0;
  --tcl-image-asset-margin-block-start: 0;
  --tcl-image-asset-margin-inline-end: 0;
  --tcl-image-asset-margin-inline-start: 0;
  --tcl-image-asset-max-inline-size: none;
  --tcl-image-asset-min-inline-size: 0;
  --tcl-image-asset-object-fit: cover;
  --tcl-image-asset-object-position-x: center;
  --tcl-image-asset-object-position-y: center;
  --tcl-image-asset-opacity: 1;
  --tcl-image-asset-position: static;
  --tcl-image-asset-transform: none;
  --tcl-image-asset-visibility: visible;
  --tcl-image-inline-size: 100%;
  --tcl-image-overflow: visible;
  --tcl-image-position: relative;
  --tcl-image-end-gradient-size: 20%;
  --tcl-image-end-gradient-opacity: 0.5;
  --tcl-image-end-gradient-end: transparent;
  --tcl-image-end-gradient-start: #000;
  --tcl-image-start-gradient-size: 20%;
  --tcl-image-start-gradient-opacity: 0.5;
  --tcl-image-start-gradient-end: transparent;
  --tcl-image-start-gradient-start: #000;
  --tcl-button-group-grid-auto-flow: row;
  --tcl-button-group-inline-size: 100%;
  --tcl-homepage-hero-footer-padding-block-end: var(--tds-size--7x);
  --tcl-homepage-hero-inline-size: 100%;
  --tcl-homepage-hero-asset-offset-x-desktop: 0px;
  --tcl-homepage-hero-asset-offset-x-mobile: 0px;
  --tcl-homepage-hero-asset-offset-y-desktop: 0px;
  --tcl-homepage-hero-asset-offset-y-mobile: 0px;
  --tcl-homepage-hero-heading-font-size-desktop: var(--tds-font-size--80, 40px);
  --tcl-homepage-hero-heading-font-size-mobile: 36px;
  --tcl-homepage-hero-heading-opacity: 1;
  --tcl-homepage-hero-heading-transition: transform 0.5s ease-in-out 0.5s, opacity 0.5s ease-in-out 0.5s;
  --tcl-homepage-hero-subcopy-transition: transform 0.5s ease-in-out 1s, opacity 0.5s ease-in-out 1s;
  --tcl-homepage-hero-overlay-display: grid;
  --tcl-homepage-hero-subcopy-font-size-desktop: inherit;
  --tcl-homepage-hero-subcopy-font-size-mobile: inherit;
  --tcl-body-accessible-background-color: #757575;
  --tcl-body-background-color: var(--tds-color--white);
  --tds-border_width--hairline: 0.5px;
  --tds-border_width--small: 1px;
  --tds-border_width--medium: 3px;
  --tds-border_style--base: solid;
  --vh: 8.96px;
  -webkit-font-smoothing: antialiased;
  --tds-font-family--combined: var(--tds-font-family-latin), var(--tds-font-family) !important;
  --tds-font-family-combined: var(--tds-font-family-latin), var(--tds-font-family) !important;
  --tds-shell-content-height: calc(100vh - var(--tds-shell-header-height) - var(--tds-shell-footer-height));
  --tcl-top-banner-height: 92px;
  --tcl-footer-height: 112px;
  --tds-color--primary: var(--tds-color-grey-10);
  --tds-color--primary-highlight: var(--tds-color-grey-20);
  --tds-theme-foreground-on-primary: var(--tds-color--white);
  --tds-theme-primary: var(--tds-color-grey-10);
  --tds-theme-primary-highlight: var(--tds-color-grey-20);
  --tcl-homepage-hero-block-size: calc(var(--vh, 1vh)*100 - var(--tcl-top-banner-height, 0px));
  --tcl-image-asset-block-size: var(--tcl-homepage-hero-block-size);
  --tcl-video-asset-block-size: var(--tcl-homepage-hero-block-size);
  --tcl-homepage-hero-content-opacity: 0.95;
  -webkit-box-direction: normal;
  --tcl-homepage-hero-buttons-padding-bottom-offset-desktop: 0vh;
  --tcl-homepage-hero-buttons-padding-bottom-offset-mobile: 0vh;
  box-sizing: border-box;
  scroll-behavior: smooth;
  outline-offset: 2px;
  appearance: none;
  text-decoration: none;
  --tds-btn--border-color: transparent;
  --tds-btn--border-radius: var(--tds-border-radius--pill);
  align-items: center;
  background-color: var(--tds-btn--background-color);
  block-size: var(--tds-height--pill);
  border: var(--tds-border-width--large) solid var(--tds-btn--border-color);
  border-radius: var(--tds-btn--border-radius);
  box-shadow: inset 0 0 0 var(--tds-border-width--medium) transparent;
  display: inline-flex;
  font-family: var(--tds-font-family--combined);
  font-size: var(--tds-btn--font-size);
  font-weight: var(--tds-font-weight--medium);
  justify-content: center;
  line-height: 1.2;
  padding: var(--tds-size--half) var(--tds-size--3x);
  position: relative;
  text-align: center;
  inline-size: -webkit-fill-available;
  color: var(--tds-theme-foreground);
  backdrop-filter: var(--tcl-button-backdrop-filter);
  margin-bottom: var(--tcl-button-margin-block-end);
  margin-inline-end: var(--tcl-button-margin-inline-end);
  margin-inline-start: var(--tcl-button-margin-inline-start);
  margin-top: var(--tcl-button-margin-block-start);
  margin-right: var(--tcl-button-margin-inline-end);
  margin-left: var(--tcl-button-margin-inline-start);
  min-width: var(--tcl-button-min-inline-size) !important;
  --tcl-button-group-button-inline-size: calc(var(--tds-size--1x)*33);
  --tcl-button-min-inline-size: calc(var(--tds-size--1x)*33);
  inset-inline-start: 0;
  text-transform: var(--tcl-button-text-transform);
  transition: all .5s ease-in-out 1s;
  cursor: pointer;
  left: 0;
  --tds-btn--background-color: rgba(244, 244, 244, 0.8);
  --tcl-button-backdrop-filter: blur(8px);

}



.viewMore a {
  font-size: 17px;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  color: white;
  border-bottom: solid 2px red;

}

.flex-box-header .headerText a {
  text-decoration: none;
}

.flex-box-header .headerText a p {
  text-decoration: none;

}

#detail {
  position: fixed;

}

.header {
  position: absolute;
}



.header h2 {
  padding-left: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: white;
}

.headerTextOnPortfolio {
  /* background-color: red; */

  display: flex;
  width: 45vw;
  flex-direction: row;
}


#aboutUsHeader {
  font-family: "Pixelify Sans", sans-serif;
  color: white;
  text-decoration: none;

  font-size: "30px"

}

h2 {
  font-family: "Pixelify Sans", sans-serif;
}

#contactUsHeader {
  font-family: "Pixelify Sans", sans-serif;
  color: white;
  text-decoration: none;
}

.headerText {
  /* background-color: red; */
  display: flex;
  width: 50vw;
  flex-direction: row;
  z-index: 1;
  font-family: 'Poppins', sans-serif;
  color: white;
  text-decoration: none;

}

/* Responsive Footer Styles */
.footer {
  background-color: #333;
  color: #fff;
  padding: 8px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}

.footer p {
  margin: 0;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
  padding-bottom: '25px';
}



.menuItemsOnPortfolio {
  background-color: black;
  /* display: flex;
  width: 80vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 140px;
  padding-right: 35px;
  z-index: 1; */


  display: flex;
  width: 50vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 10vw;
  z-index: 1;
}


.menuItems {
  /* background-color: green; */

  display: flex;
  width: 50vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* padding-left: 150px; */
  z-index: 1;
}

.menuItemsOnPortfolio a {
  text-decoration: none;
}

.menuItemsOnPortfolio a p {
  tab-size: 4;
  font-family: Roboto, sans-serif;
  --tw-bg-opacity: 1;
  text-align: center;
  line-height: 22px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  z-index: 1;
  /* max-width: 320px; */
  /* min-width: 200px; */
}

.menuItems a {
  text-decoration: none;
}

.menuItems a p {
  tab-size: 4;
  font-family: Roboto, sans-serif;
  --tw-bg-opacity: 1;
  text-align: center;
  line-height: 22px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  z-index: 1;
  /* max-width: 320px; */
  /* min-width: 200px; */
}

/* MARK - END HEADER */


/* MARK - START PAGE */

.home-text-container {
  text-align: center;
  background-color: black;
}

.container p a {
  color: blue;
}

.tagline h3 {
  background-color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: white;
  text-align: center;
}

.header-container img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.menuText a p {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  font-family: Roboto, sans-serif;
  --tw-bg-opacity: 1;
  text-align: right;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scroll-snap-strictness: proximity;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  margin-left: auto;
  margin-right: auto;
  display: block;

  padding-bottom: 30px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 320px;
  min-width: 200px;
}

.infoTextGames {

  font-family: 'Montserrat', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 28px;


  --tw-bg-opacity: 1;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 400px;
  min-width: 200px;
}

.infoTextMedia {
  font-family: Roboto, sans-serif;
  --tw-bg-opacity: 1;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 400px;
  min-width: 200px;
  font-size: 18px;
  line-height: 28px;

}

.infoTextSoftware {
  font-family: Roboto, sans-serif;
  --tw-bg-opacity: 1;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 400px;
  min-width: 200px;
  font-size: 18px;
  line-height: 28px;
}

.container h4 {
  font-family: 'Archivo', sans-serif;
  font-size: 45px;
  font-weight: 500;
}

.angry-grid3 {
  display: grid;

  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "one two"
    "one two";
  ;
  gap: 0px;
  height: 55%;
  padding-top: 20vh;

}

.angry-grid {
  display: grid;

  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "one two"
    "one two";

  gap: 0px;
  height: 55%;
  padding-top: 20vh;

}

#item-0 {
  grid-area: one;
  margin-left: 200px;

}


#detail-footer {
  position: relative;
}


.footer {
  background-color: black;
  color: white;
  padding: 8px;
  text-align: center;
  position: sticky;
  top: 100%;
  width: 100vw;
  height: auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.footer-link {
  margin: 0 10px;
}

.footer-link-image {
  width: 30px;
  height: 30px;
}

.footer-text {
  margin-top: 10px;
}

.form-container input::placeholder {
  color: grey
}


.form-container {
  margin-top: -35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.centered-form {
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
}

.centered-form h2 {
  text-align: left;
  color: white;
}

.form-group {
  margin: 10px 0;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


#about1 {
  padding-left:   175px;
  padding-right: 185px;
  -webkit-text-size-adjust: auto;
}

#about2 {
  padding-left: 15%;
  padding-right: 15%;
  -webkit-text-size-adjust: auto;

}

#about3 {
  padding-left: 15%;
  padding-right: 15%;
  -webkit-text-size-adjust: auto;

}






/* Media query for responsiveness */
@media (max-width: 768px) {




  /* .footer {
    height: 17%
  } */


  #about1 {
    padding-left: 35px;
    padding-right: 35px;
  }

  #about2 {
    padding-left: 35px;
    padding-right: 35px;
  }

  #about3 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .centered-form {
    width: 90%;
    padding: 10px;
  }

  .footer-links {
    flex-wrap: wrap;
  }

  .footer-link {
    margin: 5px;
  }
}
















#item-0 h4 {
  text-align: left;
  color: white;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  --swiper-theme-color: #007aff;
  --swiper-navigation-size: 44px;
  line-height: inherit;
  font-family: 'Archivo', sans-serif;

  --tw-bg-opacity: 1;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;

  margin: 59.850px 0px 59.850px 0px;
  margin-left: auto;
  margin-right: auto;
  font-size: 45px;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}


#item-1-games h4 {
  color: white;
  text-align: left;
  /* padding-left: 200px; */
}

#item-1-games {
  grid-area: two;
  margin-left: 20px;
}

#item-1 h4 {
  color: white;
  text-align: left;
  /* padding-left: 200px; */
}

#item-1 {
  grid-area: two;
  margin-left: 20px;
}

.footer-container p {
  font-family: Roboto, sans-serif;
  color: white;
  text-align: center;
  padding-top: 10px;
}

.footer-container-portfolio {
  background-color: black;
  color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 92%;
  bottom: 0;
  width: 100vw;
  height: 125px;
}

.footer-container {
  background-color: black;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 92%;
  bottom: 0;
  width: 100vw;
  height: 125px;
}



.container {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: scroll;
}


video {
  width: 100vw;
  height: 100dvh;
  object-fit: cover;
  object-position: center;
  z-index: 0;

}

.section-home {

  /* background-image: url('./script/test-img/backgroundVideo.mp4'); */
  height: 100dvh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}




.footer-logo {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.section-footer {

  height: 100dvh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-games {
  background-image: url('../public/images/randomTV.PNG');
  border-radius: 10px;
  max-width: 560px;
  height: 56vh;
  margin-right: -120.5px;
  margin-top: 40px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-about {
  background-image: url('../public/images/about.PNG');
  border-radius: 10px;
  max-width: 560px;
  height: 56vh;
  margin-right: -120.5px;
  /* margin-left: 50px; */
  margin-top: 40px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-media {
  background-image: url('../public/images/cars.PNG');
  border-radius: 10px;
  max-width: 560px;
  height: 56vh;
  margin-right: -120.5px;
  margin-top: 40px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-software {
  background-image: url('../public/images/computerspace.PNG');
  border-radius: 10px;
  max-width: 560px;
  height: 56vh;
  margin-right: -120.5px;
  margin-top: 40px;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.gridContainer div:hover {
  outline: 5px solid red;
  border-radius: 3.75px;
}

.gridContainer div div:hover {
  outline: none
}


.section {
  height: 100dvh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}





input {
  padding-left: 40px;
  box-shadow: 2px 3px 28px 1px rgba(0, 0, 0, 0.1);
  border: 0px solid transparent;
  border-radius: 5px;
  margin-right: -30px;
  height: 28px;
  font-size: 15px;
}

 .dropdDown {
  text-align: 'right';
  margin-bottom: '5px';
  margin-top: '10px';
  border-radius: '0px !important';
   margin-right: '50px' 
 }
 
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  row-gap: 75px;
  column-gap: 50px;
  overflow-x: hidden;
  padding: "5vh 15px 175px 15px"
  
}




* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}



/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}


.container-card {
  display: flex;
  flex-wrap: wrap;
  margin-left: "-5px";
  margin-right: "-5px";
  justify-content: space-evenly;

}

.portfoliotype {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0 0 15px;
  line-height: 30px;
  font-size: 14px;
  margin-bottom: 5px;
  color: #c6c9d8;
}

.title {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0 0 15px;
  font-family: Poppins, sans-serif;
  line-height: 1.4074;
  color: #1f1f25;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.info-button {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: left;
  font-size: 14px;
  line-height: 1.71429;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;
  margin-top: 35px;
  transition: .7s;
  opacity: 0;
  visibility: hidden;
}


#world {
  display: none;
}

/* ::-webkit-scrollbar {
  display: none;
} */


/* Responsive Breakpoints */

@media only screen and (max-width: 1928px) {




.small-text {
  font-size: 34px;
  line-height: 32px;
}

  .gridContainer {
    padding-top: 5px;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 5px;
    padding-left: 85px;
    padding-right: 100px;
  }



  .container {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

}

@media only screen and (max-width: 1728px) {
  .gridContainer {
    padding-bottom: 5px;

    grid-template-columns: repeat(3, 1fr)
  }

 

}

@media only screen and (max-width: 1280px) {



  .gridContainer {
    grid-template-columns: repeat(3, 2fr);
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
  
  }
}

@media only screen and (max-width: 1200px) {


  .footer {
    height: 200px;

  }

  .gridContainer {
    grid-template-columns: repeat(2, 2fr);
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;

  }

  html {
    scroll-behavior: smooth;
  }


  #about1 {
    padding-left: 35px;
    padding-right: 35px;
  }

  #about2 {
    padding-left: 35px;
    padding-right: 35px;
  }

  #about3 {
    padding-left: 35px;
    padding-right: 35px;
  }

  #portfolio {
    overflow: scroll;
  }

  #world {
    display: inline;
  }

  .menuItemsOnPortfolio {
    padding-left: 50vw;

  }

  .flex-box-header {
    flex-direction: column;
    width: 100vw;
    justify-content: center;
  }

  .header h2 {
    padding-left: 0;
  }

  .headerText {
    width: 100vw;
    justify-content: center;
    text-align: center;

  }

  .menuItems {
    width: 100vw;
    justify-content: space-evenly;
  }

  #search {
    display: none;
  }

  .infoTextGames {
    text-align: center;
  }

  .infoTextMedia {
    text-align: center;
  }

  .infoTextSoftware {
    text-align: center;
  }

  .angry-grid #item-0 {

    margin-left: 0;
    text-align: center;

  }

  .angry-grid #item-1 {
    display: none;

  }

  .angry-grid3 #item-0 {
    margin-left: 0;
    text-align: center;
    margin-top: -30px;


  }



  .footer-container-portfolio {
    position: relative;
  }

  html {
    background-color: black;
  }








  .angry-grid3 #item-1 {

    display: none;


  }

  .angry-grid {
    grid-template-areas:
      "one one"
      "two two";
    ;
    justify-items: center;
  }

  .angry-grid3 {
    grid-template-areas:
      "one one"
      "two two";
    ;
    justify-items: center;
  }

  #item-0 h4 {
    text-align: center;
  }

  #item-1 h4 {
    text-align: center;
  }

}

@media only screen and (max-width: 750px) {
  .tag-container {
    flex-direction: column;
    gap: 5px;
  }



  
html {
  overflow-x: hidden;
}

#footerBackground html {
  overflow: hidden;
}


  .footer {
    height: 300px;
    overflow: hidden;
  }

  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }

  #about1 {
    padding-left: 8px;
    padding-right: 8px;
  }

  #about2 {
    padding-left: 40px;
    padding-right: 40px;
  }

  #about3 {
    padding-left: 20px;
    padding-right: 20px;
  }

  #portfolio {
    overflow: scroll;
  }

  #world {
    display: inline;
  }


  .menuItemsOnPortfolio {
    padding-left: 50vw;

  }




  .flex-box-header {
    flex-direction: column;
    width: 100vw;
    justify-content: center;
  }

  .header h2 {
    padding-left: 0;
  }

  .headerText {
    width: 100vw;
    justify-content: center;
    text-align: center;
  }

  .menuItems {
    width: 100vw;
    justify-content: space-evenly;
  }

  .menuItemsPortfolio {
    width: 100vw;
    justify-content: space-between;
  }

  #search {
    display: none;
  }

  .infoTextGames {
    text-align: center;
    max-width: 340px;

  }

  .infoTextMedia {
    text-align: center;
    max-width: 340px;
  }

  .infoTextSoftware {
    text-align: center;
    max-width: 340px;

  }




  .angry-grid #item-0 {
    margin-left: 0;
    margin-top: -35px;
    text-align: center;
  }

  .angry-grid #item-1 {

    display: none;


  }

  .angry-grid3 #item-0 {

    margin-left: 0;
    margin-top: -25px;

    text-align: center;


  }

  .gridContainer {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    overflow-x: scroll;
    grid-template-columns: repeat(1, 1fr);

  }

  .footer-container-portfolio {
    position: relative;
  }

  html {
    background-color: black;
  }





  .angry-grid3 #item-1 {
    display: none;
  }

  .angry-grid {
    grid-template-areas:
      "one one"
      "two two";
    ;
    justify-items: center;
  }

  .angry-grid3 {
    grid-template-areas:
      "one one"
      "two two";
    ;
    justify-items: center;
  }

  #item-0 h4 {
    text-align: center;
  }

  #item-1 h4 {
    text-align: center;
  }

}

@media only screen and (max-width: 360px) {



  .gridContainer div:hover {
    outline: none;
    border-radius: none;
  }

  .tag-container {
    flex-direction: column;
  }

  .left-tag {
    margin-top: 5px;
  }

  h2.headerTextCompany {
    font-size: 24px;
  }

  div.menuItems {
    font-size: 14px;
  }

  p.textBig {
    font-size: 20px;
  }

  p.small-text {
    font-size: 12px;
  }

  #about1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  div.gridContainer {
    padding-left: 0px;
    margin-right: 7px;
    margin-left: 7px;
    overflow-x: hidden;
  }

  #aboutUsHeader h2 {
    font-size: 14px;
  }

  div.game-details {
    width: 100px;
  }

  div.game-details p {
    font-size: 8px;
  }

  div.creative-portfolio {
    overflow-x: hidden;
    min-height: 100vh;
    background: #0b0b0b;
  }

}

@media only screen and (max-width: 345px) {

  .gridContainer div:hover {
    outline: none;
    border-radius: none;
  }

  .tag-container {
    flex-direction: column;
  }

  .left-tag {
    margin-top: 5px;
  }

  h2.headerTextCompany {
    font-size: 24px;
  }

  div.menuItems {
    font-size: 14px;
  }

  p.textBig {
    font-size: 20px;
  }

  p.small-text {
    font-size: 12px;
  }

  #about1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  div.gridContainer {
    padding-left: 0px;
    margin-right: 7px;
    margin-left: 7px;
    overflow-x: hidden;
  }

  #aboutUsHeader h2 {
    font-size: 14px;
  }

  div.game-details {
    width: 100px;
  }

  div.game-details p {
    font-size: 8px;
  }

  div.creative-portfolio {
    overflow-x: hidden;
  }



}